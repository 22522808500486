.model-backdrop {
    backdrop-filter: blur(5px);
}
.product-search-main{
    padding: 0 !important;
    margin: 10px !important;
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
}
/* .css-ypiqx9-MuiDialogContent-root .product-search-main{
    padding: 0 !important;
    margin: 10px 0 !important;
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
} */
.search-product-margin{
    margin-top: 15px;
    /* padding-bottom: 15px; */
    cursor: pointer;
    border-bottom: 1px solid #ccc;
}
.search-model-width{
    width: auto !important;
}
.search-item-title{
    line-height: 1.2 !important;
    font-size: 0.9rem !important;
}
.search-item-price{
    font-size: 1rem !important;
    color: var(--pricecolor) !important;
    margin-left: 10px !important;
}
/* .search-more-items{
    padding: 10.5px 14px !important;
} */
.sm-title-product{
    border-radius: 20px !important;
    max-width: 770px !important;
    min-width: 320px !important;
    width: 100%;
    max-height: calc(98% - 24px) !important;
}
.search-model-setup .sm-title-product{
    max-width: 470px !important;
}
.customised-search-sm{
    margin: 0 !important;
}
.customizable-name{
    margin-left: 10px;
    display: flex;
}
/* .css-1uwgr7b-MuiTypography-root, .css-hlnzui-MuiTypography-root, .modifier-tile .css-ahj2mt-MuiTypography-root{
    font-size: 0.9rem !important;
} */
/* .css-1vam7s3-MuiGrid-root{
    justify-content: center;
} */
/* .customizable-name .css-1i1baym-MuiChip-root{
    margin-left: 5px;
} */
.content-box-set{
    width: 100%;
}
.content-box-set h6{
    font-family: "Inter", sans-serif !important;
    font-size: 0.90rem !important;
}
.content-box-set h5{
    font-family: "Inter", sans-serif !important;
    font-size: 0.88rem !important;
}
.set-nv{
    display: flex;
}
.store-locater-box{
    border-radius: 20px !important;
}
.ssl-box{
    display: flex;
}
.ssl-icon{
    margin-right: 10px;
}
.ssl-content{
    width: 80%;
}
.css-8bl5er{
    margin-right: 6px;
}
/* .css-tlc64q-MuiPaper-root-MuiDialog-paper{
    border-radius: 20px !important;
} */
.set-nv .veg-flag{
    margin-left: 7px;
    margin-right: 0;
}
.no-search-found {
    text-align: center;
    margin-top: 10px;
}
.no-search-found p{
    font-size: 0.8rem !important;
}
.no-search-found span {
    font-weight: 600;
}
.sm-title-product h2{
    font-size: 1.2rem !important;
}
@media (min-width: 320px) and (max-width: 768px) {
    .sm-title-product {
        border-radius: 20px !important;
        max-width: 90% !important;
        min-width: 320px !important;
        width: 100%;
        max-height: calc(80% - 64px) !important;
        margin: 0 !important;
    }
}
