
.basket {
  width: 100% !important;
  max-width: -webkit-fill-available;
  background-color: white !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
  padding: 10px 10px !important;
  /* margin: 0 20px 10px 0 !important; */
}

.basket-header {
  display: flex !important;
  align-items: center !important;
  padding-bottom: 10px !important;
}

.basket-icon {
  margin-right: 10px !important;
  /* color: var(--darkblue) !important; */
  color: #000 !important;
}

.basket-title {
  font-weight: bold !important;
  /* color: var(--darkblue) !important; */
  color: #000 !important;
}
.code-text{
  /* color: var(--darkblue); */
  color: #000;
  font-size: 1rem !important;
  text-transform: capitalize;
}
.code .icon{
  /* background-color: #004d40; */
  background-color: #000 !important;
    color: white;
    padding: 8px;
    border-radius: 50%;
    font-weight: bold;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    text-align: center;
    margin-bottom: 0;
}
.basket-list {
  padding: 0 !important;
}

.basket-item {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 10px 0 !important;
}

.basket-item-icon {
  color: red !important;
  margin: 0 5px !important;
}

.basket-item-text {
  flex-grow: 1 !important;
  margin: 0 10px !important;
}

.basket-item-price {
  font-weight: bold !important;
  color: green !important;
}
.swichsetup{
  margin-top: 10px;
  margin-bottom: 10px;
}
.basket-summary {
  padding: 10px 0 !important;
}

.basket-summary-item {
  display: flex !important;
  justify-content: space-between !important;
  padding: 5px 0 !important;
}

.basket-offer-button {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
  margin-top: 10px !important;
}

.basket-offer-icon {
  color: green !important;
  display: inherit;
}
.checkout-icon-cart{
  padding: 5px 0px !important;
}

.basket-total-button {
  width: 100%;
  /* background-color: #C72F3C; */
  color: white;
  margin-top: 10px;
  font-weight: 700;
  font-size: 18px;
  padding: 10px 0px;
  /* border-radius: 5px; */
  color: var(--darkblue);
  margin-bottom: 0;
  border-top: 1px dashed #c3c3c3;
}
.basket-offer-icons{
  color: #fff !important;
  margin-right: 5px !important;
  margin-top: 10px !important;
}
.basket-checkout-button{
  font-size: 18px !important;
}

.basket-checkout-button {
  width: 100% !important;
  background-color: var(--darkblue) !important;
  color: white !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
  font-size: 0.9rem !important;
  font-weight: 700 !important;
}
.setpmoimg{
  width: 100%;
}

.basket-delivery {
  margin-top: 20px !important;
  padding: 10px !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px !important;
}

.basket-delivery-options {
  justify-content: space-between !important;
}

.basket-delivery-address {
  margin-top: 10px !important;
}

.basket-submit-button {
  width: 100% !important;
  margin-top: 10px !important;
}
.cart-line-item{
  box-shadow: none !important;
  border-bottom: 1px dotted #c3c3c3 !important;
  margin-bottom: 10px !important;
}
.total-to-pay {
  color: black;
}
.order-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.order-label {
  display: flex;
  align-items: center; /* Keep icon and text aligned */
  gap: 5px; /* Small gap between icon and text */
}

.order-icon {
  font-size: 24px; /* Adjust icon size if needed */
}

.order-text {
  font-size: 16px;
  font-weight: 500;
}

.order-select {
  min-width: 160px !important;
  margin: 0 !important; /* Remove extra margin */
}

@media (min-width: 320px) and (max-width: 480px) {
  .card{
    margin: 0 0px 18px 0 !important;
  }
  .basket {
    display: block;
    max-width: 100% !important;
  }
  .css-zitybv{
    margin-bottom: 140px !important;
    margin-top: 14px !important;
  }
  .basket {
    width: auto !important;
    max-width: auto !important;
    background-color: white !important;
    border-radius: 8px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
    padding: 10px 10px !important;
     margin: 0 0px 0 0 !important; 
  }
  
}

@media (min-width: 481px) and (max-width: 429px) {
  .basket {
    display: block;
  }
  .bottom-margin-cart{
    margin-bottom: 140px !important;
  }
  .basket {
    width: auto !important;
    max-width: auto !important;
    background-color: white !important;
    border-radius: 8px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
    padding: 10px 10px !important;
   margin: 0 0px 0 0 !important; 
}

}
@media (min-width: 430px) and (max-width: 860px) {
  .basket {
    display: block;
  }
  .basket {
    width: auto !important;
    max-width: 100% !important;
    background-color: white !important;
    border-radius: 8px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
    padding: 10px 10px !important;
   margin: 0 0px 0 0 !important; 
}
}

@media (min-width: 861px) and (max-width: 1023px) {
  .basket {
    display: block;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .basket {
    max-width: -webkit-fill-available !important;
  }
}

@media (min-width: 1281px) and (max-width: 1919px) {
  .basket {
    /* max-width: 335px !important; */
    max-width: 92% !important; 
  }
}