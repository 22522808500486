.offers-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

/* Horizontal scroller container */
.offers-scroller {
    display: flex;
    overflow-x: auto;
    padding: 10px 0;
    gap: 15px;
    /* scrollbar-width: thin;
    scrollbar-color: var(--darkblue) transparent; */
    margin-bottom: 12px;
    -webkit-font-smoothing: antialiased;
}

/* .offers-scroller::-webkit-scrollbar {
    height: 8px;
} */

.offers-scroller::-webkit-scrollbar-track {
    background: transparent;
}
/***** scroll setup start ***/
/* Target the scrollbar of elements with the class .css-h4y409-MuiList-root */
.offers-scroller::-webkit-scrollbar {
    width: 12px; /* Width of the entire scrollbar */
    height: 4px; /* Height of the horizontal scrollbar */
}

/* The scrollbar track (background of the scrollbar) */
.offers-scroller::-webkit-scrollbar-track {
    background: #ddd; /* Track color */
    border-radius: 20px; /* Rounded corners for the track */
}

/* The draggable part of the scrollbar (the thumb) */
.offers-scroller::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar thumb color */
    border-radius: 20px; /* Rounded corners for the thumb */
    /*border: 3px solid #ddd;*/ /* Gives some space around the thumb */
}

/* On hover, make the scrollbar thumb darker for better visibility */
.offers-scroller::-webkit-scrollbar-thumb:hover {
    background-color: var(--darkblue);
}

/* Optional: Style for the scrollbar's corner (where the horizontal and vertical scrollbars meet) */
.offers-scroller::-webkit-scrollbar-corner {
    background-color: #ddd;
}

/**** scroll setup end ***/
.offer-card {
    flex: 0 0 auto;
    width: 200px;
    background-color: #fefcfb;
    /* background-color: #dde7c7; */
    /* background: linear-gradient(180deg, rgba(248, 184, 139,0) 20%, rgba(248, 184, 139,.1) 20%, rgba(248, 184, 139,.1) 40%, rgba(248, 184, 139,.2) 40%, rgba(248, 184, 139,.2) 60%, rgba(248, 184, 139,.4) 60%, rgba(248, 184, 139,.4) 80%, rgba(248, 184, 139,.5) 80%),
    linear-gradient(45deg, rgba(250, 248, 132,.3) 20%, rgba(250, 248, 132,.4) 20%, rgba(250, 248, 132,.4) 40%, rgba(250, 248, 132,.5) 40%, rgba(250, 248, 132,.5) 60%, rgba(250, 248, 132,.6) 60%, rgba(250, 248, 132,.6) 80%, rgba(250, 248, 132,.7) 80%),
    linear-gradient(-45deg, rgba(186, 237, 145,0) 20%, rgba(186, 237, 145,.1) 20%, rgba(186, 237, 145,.1) 40%, rgba(186, 237, 145,.2) 40%, rgba(186, 237, 145,.2) 60%, rgba(186, 237, 145,.4) 60%, rgba(186, 237, 145,.4) 80%, rgba(186, 237, 145,.6) 80%),
		linear-gradient(90deg, rgba(178, 206, 254,0) 20%, rgba(178, 206, 254,.3) 20%, rgba(178, 206, 254,.3) 40%, rgba(178, 206, 254,.5) 40%, rgba(178, 206, 254,.5) 60%, rgba(178, 206, 254,.7) 60%, rgba(178, 206, 254,.7) 80%, rgba(178, 206, 254,.8) 80%),
    linear-gradient(-90deg, rgba(242, 162, 232,0) 20%, rgba(242, 162, 232,.4) 20%, rgba(242, 162, 232,.4) 40%, rgba(242, 162, 232,.5) 40%, rgba(242, 162, 232,.5) 60%, rgba(242, 162, 232,.6) 60%, rgba(242, 162, 232,.6) 80%, rgba(242, 162, 232,.8) 80%),
    linear-gradient(180deg, rgba(254, 163, 170,0) 20%, rgba(254, 163, 170,.4) 20%, rgba(254, 163, 170,.4) 40%, rgba(254, 163, 170,.6) 40%, rgba(254, 163, 170,.6) 60%, rgba(254, 163, 170,.8) 60%, rgba(254, 163, 170,.8) 80%, rgba(254, 163, 170,.9) 80%); */
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
    border: 1px solid #fefcfb;
}

.offer-card:hover {
    transform: translateY(-5px);
    background-color: var(--offerhover);
    border: 1px solid #00942e;
}

/* .offer-icon {
    background-color: #006400;
    color: white;
    padding: 5px 10px;
    border-radius: 50%;
    font-size: 20px;
    margin-bottom: 10px;
  } */
.offer-card .offer-image {
    width: 100%;
}
.offer-title {
    font-size: 0.96rem;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 0;
    text-transform: capitalize;
}

.offer-description {
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 0;
}

.read-more-offer{
    cursor: pointer;
    font-weight: 600;
    font-size: 0.75rem;
    color: var(--links);
}

.offer-code {
    font-size: 12px;
    color: #555;
}
@media (min-width: 320px) and (max-width: 600px) {
    .offer-card{
        width: 40%;
        padding: 10px;
    }
}
@media (min-width: 600px) and (max-width: 900px) {
    .offer-card{
        width: 20%;
        padding: 10px;
    }
}