.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 2;
}

.header-left,
.header-right {
  display: flex;
  align-items: center;
}
.header-right svg{
  color: var(--headericons);
}
/* .header-home{
  margin-left: 40% !important;
  padding: 10px 20px;
  border-radius: 10px;
  background: #cde8d5;
  border:1px solid #cde8d5;
}
.header-home:hover{
  margin-left: 40% !important;
  padding: 10px 20px;
  border-radius: 10px;
  border:1px solid #00942e;
} */
.header-price-box {
  background-color: var(--darkblue);
  padding: 5px 10px; /* Padding around the price */
  border-radius: 15px; /* Rounded corners */
  display: flex;
  border-color: #000;
  align-items: center;
  margin-left: 10px; /* Space between elements */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}
.header-right button:hover{
  background-color: #fff;
}
/* .header-right button .css-8je8zh-MuiTouchRipple-root{
  display: block;
  position: relative;
} */
.header-price-text {
  font-weight: bold;
  color: white; /* Text color */
}

.header-logo {
  height: 40px;
  /* margin-right: 20px !important; */
}

.header-icon {
  margin-right: 8px !important;
}

.header-text {
  margin-right: 20px !important;
}

.header-button {
  margin-right: 20px !important;
  background-color: black;
  color: white;
  border-radius: 50px !important;
}

.header-button:hover {
  background-color: darkgray;
}

.menu-button {
  display: none;
}

.menu-icon {
  margin-right: 10px;
  font-size: 20px;
  color: #555;
}

.menu-text {
  font-size: 16px;
  color: #333;
}

/* Responsive Styles */
@media (max-width: 850px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .header-left {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .header-right {
    display: none;
  }

  .menu-button {
    display: block;
  }
}
/* @media (min-width: 320px) and (max-width: 767px) {
  .header-home{
    margin-left: 20% !important;
  }
  .header-home:hover{
    margin-left: 20% !important;
  }
}
@media (min-width: 768px) and (max-width: 850px) {
  .header-home{
    margin-left: 60% !important;
  }
  .header-home:hover{
    margin-left: 60% !important;
  }
}
@media (min-width: 851px) and (max-width: 900px) {
  .header-home{
    margin-left: 5% !important;
  }
  .header-home:hover{
    margin-left: 60% !important;
  }
}
@media (min-width: 901px) and (max-width: 1023px) {
  .header-home{
    margin-left: 5% !important;
  }
  .header-home:hover{
    margin-left: 5% !important;
  }
} */