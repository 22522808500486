.product-grid-container {
  padding: 20px !important;
}

.product-grid-container h3 {
  font-weight: 600;
  color: #4F4F4D;
  margin-top: 1.5rem;
  font-size: 0.88rem;
  margin-bottom: 0.4rem;
  margin-left: 0;
    margin-right: 0;
}

.product-grid-container h3:before,
.product-grid-container h3::after {
  content: " " !important;
  display: block !important;
  /* border-bottom: 2px solid #ccc !important; */
  /* height: 1px; */
  background-color: #f8f8f8 !important;
  /* border-top: none; */
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0c8c45;
}

.product-grid-container h2 {
  font-weight: 700;
  font-size: 1rem;
  margin: 0.95rem 0;
  color: var(--sidebartitle);
  /* padding-top: 80px; */
}

.category-header span,
.offers-title span,
.featured-title span {
  background-color: #fff !important;
  padding: 7px 20px;
  border-radius: 50px !important;
  border-bottom: 2px solid !important;
}

.product-grid-container h2:first-child {
  padding-top: 20px;
  margin: 0.95rem 0;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid var(--sidebartitle);
  line-height: 0.1em;
  margin: 10px 0 20px;
  text-transform: capitalize;
}

.section-title {
  margin-bottom: 20px !important;
}

.title1 {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid var(--sidebartitle);
  line-height: 0.1em;
  margin: 10px 0 20px;
  text-transform: capitalize;
}

.title1 span {
  background: #fff;
  padding: 7px 20px;
  border-radius: 50px;
  /* border: 1px solid var(--sidebartitle); */
}

.title2 {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #4F4F4D;
  line-height: 0.1em;
  margin: 10px 0 20px;
  text-transform: capitalize;
}

.title1 span {
  background: #fff;
  padding: 7px 20px;
  border-radius: 50px;
  /* border: 1px solid #4F4F4D; */
}

.sort-button {
  margin-bottom: 7px !important;
  float: right !important;
  background-color: #fff;
  border: none !important;
  font-size: 0.8rem !important;
}

/* .divider {
  display: flex;
  align-items: center;
} */
/* .divider:before, .divider:after {
  content: "";
  flex: 1;
} */
/* .css-lo9b43-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 0.8rem !important;
} */

.line {
  align-items: center;
  margin: 0em;
  width: 100%;
}

/* .css-zow5z4-MuiGrid-root h3 {
  font-weight: 500;
  color: #555;
} */

/* .line:before, .line:after {
  height: 1px;
  margin: 0 1em;
} */

/* .one-line:after {
  background: #d3d3d3;
  content: " ";
  display: block;
  border-bottom: 1px solid #d3d3d3;
  border-top: 1px solid #d3d3d3;
  height: 2px;
  background-color: #f1f0f5;
} */
.product-card {
  padding: 12px !important;
  text-align: center !important;
  background-color: #fff !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  position: relative !important;
  text-align: left !important;
  border: 2px solid #fff;
  margin: 10px 10px 10px 0;
}

.product-card:hover {
  transform: translateY(-8px);
  border: 1px solid #00942e;
}

.product-image {
  /* width: 100% !important; */
  /* height: 80px !important; */
  height: 70px !important;
  border-radius: 8px !important;
  margin-bottom: 10px !important;
}

.pro-titel-img-box {
  display: flex;
}

.placeholder-image {
  width: 80px;
  height: 80px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #f5f5f5 !important;
  border-radius: 8px !important;
  color: #888 !important;
  margin-bottom: 10px !important;
  padding: 5px;
  text-align: center;
  font-size: 0.9rem;
}

.product-title {
  font-weight: bold !important;
  margin-bottom: 2px !important;
  margin-left: 10px !important;
  width: 100% !important;
  font-size: 1.05rem !important;
}

.product-description {
  margin-bottom: 10px !important;
  color: #6C757D !important;
  font-size: 0.8rem !important;
}

.product-price {
  font-weight: 600 !important;
  /* color: #4c5c68 !important; */
  color: var(--pricecolor);
  margin-bottom: 2px !important;
  margin-right: 10px !important;
  width: 100% !important;
  font-size: 1rem !important;
}

.product-actions {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  flex-wrap: wrap;
  gap: 10px;
}

.customize-button {
  text-transform: none !important;
}

.add-button {
  background: var(--darkblue) !important;
  color: white !important;
  border-radius: 50% !important;
}

.add-button:hover {
  background-color: #006630 !important;
}

.remove-button {
  background: var(--darkblue) !important;
  color: white !important;
  border-radius: 50% !important;
}

.remove-button:hover {
  background-color: #006630 !important;
}
@media (min-width: 320px) and (max-width: 1023px) {
  .line{
    width: 94% !important;
  }
  .product-card{
    width: 83%;
  }
}