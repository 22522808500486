.payment-root {
    margin-top: 2%;
    text-align: center;
}

.paymentSummary {
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    /* justify-content: center; */
}
.payment-customize {
    background-color: #F6F6F6 !important;
    padding: 40px 20px !important;
}
.paymentBox {
    padding: 76px 150px;
    text-align: center;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
}

.paymentBox:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    background-color: #08415c   !important;
    color: #F6F6F6 !important;
}
.paymentBox h6{
    font-size: 1.2rem !important;
}
.payment-titles{
    font-size: 1.9rem !important;
    margin-left: 10px !important;
}
.payment-customize .direction-payment-total {
    padding-left: 16px !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.icon {
    font-size: 3rem;
    margin-bottom: 8px;
}

.divider {
    border-left: 1px solid #e0e0e0;
    height: 100%;
    margin: 0 20px;
}
.securePayment {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}
.securePayment h6{
    font-size: 1.9rem;
    margin-left: 10px;
}
.amount-setup{
    margin-left: 10px !important;
    font-weight: 600 !important;
    color: #cc2936;
    font-size: 1.5rem !important;
}
.amount-title, .amount-setup{
    font-size: 1.5rem !important;
    font-family: "Inter", sans-serif !important;
}
 .rounded-paper-payment{
    border-radius: 20px !important;
 }
.secureIcon {
    color: green;
    margin-right: 8px;
}
.img-setup-box img{
    width: 116px;
    height: auto;
}
.main-payment-option {
    width: 100% !important;
    margin-top: 0 !important;
    background: #F6F6F6;
    margin-left: 0px !important;
    padding: 20px;
    border-radius: 6px;
}
.payment-grid-item {
    padding-left: 0px !important;
    padding-top: 0px !important;
    max-width: 100% !important;
}
.main-payment-option .divider {
    display: flex;
    align-items: center;
    width: 1px;
    height: auto;
    background-color: #a5a5a5;
    margin: 10px 13px;
}
.backpartpayment {
    /* position: absolute; */
    /* width: 100%; */
    padding: 10px 0px;
    display: flex;
    align-items: center;
    background: #f0f0f0;
    border-radius: 10px;
    top: 164px;
    cursor: pointer;
}
  .backiconsetpayment{
    width: 36px;
    height: auto;
    margin-right: 10px;
    margin-left: 10px;
  }
  .backiconsetpayment img{
    width: 100%;
  }
@media (min-width: 320px) and (max-width: 960px) {
     .payment-titles {
        font-size: 1.6rem !important;
        margin-left: 10px !important;
    }
    .payment-root {
        margin-top: 12%;
    }
    .paymentBox {
        padding: 20px 30px;
        text-align: center;         
        cursor: pointer;
        transition: box-shadow 0.3s ease;
    }
    .main-payment-option .divider {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 15px;
        background-color: #c3c3c3;
        width: 100%;
        height: 1px;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .main-payment-option{
        display: block !important;
        width: 100% !important;
        margin-left: 0 !important;
        margin-top: 0 !important;
    }
    .img-setup-box img{
        width: 80px;
        height: 80px;
    }
}