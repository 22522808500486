.pf-order-error-container{
    width: 40% !important;
    margin: 0 auto !important;
    padding: 20px !important;
    text-align: center !important;
    border-radius: 10px !important;
  }
  
  .pf-icon-container {
    margin-bottom: 15px !important;
  }
  
  .pf-error-icon {
    font-size: 6.8rem !important;
    color: #f44336 !important;
  }
  
  .pf-title {
    color: #333 !important;
    margin-bottom: 10px !important;
    font-weight: 700 !important;
  }
  
  .pf-order-id {
    color: #777 !important;
    margin-bottom: 20px !important;
    font-size: 0.99rem !important;
  }
  
  .pf-order-id span{
    color: #777 !important;
    font-size: 0.99rem !important;
  }
  .pf-go-to-menu-button {
    margin-bottom: 20px !important;
  }
