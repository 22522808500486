.center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
}

.card-style {
  width: 92%;
  padding: 6px 16px; /* Reduced padding for less spacing */
  margin-bottom: 16px;
  border-radius: 12px !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
}

.order-title {
  font-weight: bold;
  color: #333;
}

.order-status {
  color: var(--links);
  font-weight: 500;
}

.order-date {
  color: #000;
  margin-bottom: 4px;
}

.history-store-info {
  color: #757575;
  font-weight: 500;
  margin-top: 10px !important;
}

.order-amount {
  font-weight: bold;
  color: #333;
}

.view-order {
  cursor: pointer;
  font-weight: bold !important;
  color: #007200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-large {
  font-size: 40px;
  color: #ff9800;
}

.divider-spacing {
  margin: 8px 0 !important; /* Reduced space between sections */
}
.oh-fastfood-icon svg {
  font-size: 6rem;
  color: #6a994e;
  margin-right: 20px;
}
.icon-btn {
  color: #007200;
}

.order-actions {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px; /* Ensures icon and text are closer together */
}
.stepper-styles {
  margin-top: 30px;
}
.stepper-css {
  font-size: 12px;
  color: #888;
}

.text-end {
  text-align: end;
}

.icon-color {
  color: var(--links);
  margin-right: 10px;
}

.qty-price {
  margin-left: 23px;
}
.order-details-block {
  background-color: #efefef;
  padding: 1rem;
  border-radius: 15px;
}
.do-historyicon{
  text-align: right;
  color: var(--links);
  cursor: pointer;
}
.od-backbutton{
  margin-bottom: 20px;
  margin-top: 5px;
  width: 100%;
}
.od-addons-style{
  margin-right: 5rem !important;
}
.od-backbtn-historybtn{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.up-historyicon{
  margin-right: 10px;
  color: var(--links);
}
.list-date-txtlight{
  color: rgb(136, 136, 136);
  font-weight: light;
}