.center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-style {
  width: 92%;
  padding: 6px 16px; /* Reduced padding for less spacing */
  margin-bottom: 16px;
  border-radius: 12px !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
}

.order-title {
  font-weight: bold;
  color: black;
}

.order-status {
  color: var(--links);
  font-weight: 500;
}

.order-status-acknowledged {
  color: var(--links); /* Green */
  font-weight: 500;
}

.order-status-food-ready {
  color: #ff9800; /* Orange */
  font-weight: 500;
}

.order-status-dispatched {
  color: #007200; /* Blue */
  font-weight: 500;
}

.order-status-completed {
  color: var(--links); /* Teal */
  font-weight: 500;
}

.order-status-cancelled {
  color: #f44336; /* Red */
  font-weight: 500;
}

.order-status-placed {
  color: var(--links);
  font-weight: 500;
}

.order-date {
  color: #757575 !important;
  margin-bottom: 4px !important;
}

.history-store-info {
  color: #757575;
  font-weight: 500;
  margin-top: 10px !important;
}

.order-amount {
  font-weight: bold;
  color: #333;
}

.view-order {
  cursor: pointer;
  font-weight: bold;
  color: #1976d2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-large {
  font-size: 40px;
  color: #ff9800;
}

.divider-spacing {
  margin: 8px 0 !important; /* Reduced space between sections */
}

.icon-btn {
  color: #007200;
}

.order-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.oh-box{
  background-color: white;
  border-radius: 10px;
}
.oh-backbutton{
  margin-bottom: 20px;
  margin-top: 20px;
  width: 95%;
}