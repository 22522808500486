.footer {
  width: 100%;
  /* background-color: #0a0a23; */
  background-color: #000;
  color: white;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;

}

.footer-mobile {
  flex-direction: column;
}

.footer-text {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.footer-links {
  display: flex;
  gap: 20px;
  margin-right: 20px;
}

.footer-links-mobile {
  flex-direction: column;
  align-items: center;
  margin-right: 0;
  gap: 0;
}

.footer-link {
  margin: 5px 0;
  cursor: pointer;
  font-size: 0.875rem;
  color: #666 !important;
}

.footer-link:hover{
  color: #fff !important;
}