.sidebar {
  width: 94%;
  /* width: 197px; */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  height: 82vh;
  display: none;
  /* Hide sidebar by default */
  margin-top: 5px;
  /* scrollbar-width: thin;
  scrollbar-color: #000 transparent; */
  -webkit-font-smoothing: antialiased;
}
.sidebar nav{
  height: 72vh !important;
    overflow-y: auto !important;
}

.nav-category-list span{
  font-size: 0.8rem !important;
}
/* Target the scrollbar of elements with the class .css-h4y409-MuiList-root */
/* .css-h4y409-MuiList-root::-webkit-scrollbar {
  width: 12px;
  height: 12px;
} */

/* The scrollbar track (background of the scrollbar) */
/* .css-h4y409-MuiList-root::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
} */

/* The draggable part of the scrollbar (the thumb) */
/* .css-h4y409-MuiList-root::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
} */

/* On hover, make the scrollbar thumb darker for better visibility */
/* .css-h4y409-MuiList-root::-webkit-scrollbar-thumb:hover {
  background-color: #0c8c45;
} */

/* Optional: Style for the scrollbar's corner (where the horizontal and vertical scrollbars meet) */
/* .css-h4y409-MuiList-root::-webkit-scrollbar-corner {
  background-color: #ddd;
} */

.sidebar-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background: var(--sidebartitle);
  border-radius: 8px 8px 0 0;
}

.sidebar-icon {
  color: white !important;
  margin-right: 10px !important;
  min-width: 26px !important;
}

.sidebar-title {
  color: #fff;
  font-weight: 600 !important;
    font-size: 0.99rem !important;
}

.sidebar-item {
  padding: 10px 20px !important;
  border-bottom: 1px dotted #ccc !important;
}

.sidebar-item:hover {
  background-color: var(--sidebarnav);
}

.sidebar-item.active {
  background: var(--sidebarnav);
  color: white;
  /* border-bottom: 2px solid var(--bordercolor) !important; */
}
/* .css-1yh4fwk-MuiButtonBase-root-MuiListItem-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
  color: #333;
} */
/* .css-10hburv-MuiTypography-root{
  font-size: 0.8rem !important;
} */
/* .css-h4y409-MuiList-root::-webkit-scrollbar {
  width: 4px;
} */
/* .css-h4y409-MuiList-root::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px;
} */
/* .css-h4y409-MuiList-root::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
} */
.sidebar-item.active:hover {
  background-color: var(--darkblue);
}

/* .sidebar-item .MuiListItemText-root {
  color: inherit;
} */
/* .css-cveggr-MuiListItemIcon-root{
  justify-content: end !important;
} */
/* .css-h4y409-MuiList-root {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 72vh;
  overflow-x: hidden;
  overflow-y: auto;
} */
/* .css-h4y409-MuiList-root{
  scrollbar-width: thin;
} */
@media (min-width: 769px) {
  .sidebar {
    display: block;
    /* Show sidebar on screens from 768px and up */
  }

  .sidebar-toggle-button {
    display: none;
    /* Hide toggle button on screens from 768px and up */
  }
}

@media (max-width: 767px) {
  .sidebar {
    display: none;
    /* Hide sidebar on screens smaller than 768px wide */
  }

  .sidebar-toggle-button {
    display: block;
    position: fixed !important;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
  }
}